<template>
  <div>
    <h1 class="title">Advance Your Digital Career Bridge</h1>
    <span class="link">
      Already on a pathway? <a href="/signin"> Sign In </a>
    </span>
    <b-steps
      v-model="activeStep"
      :animated="true"
      :rounded="true"
      :has-navigation="false"
      size="is-large"
      class="onboarding-header"
    >
      <template
        slot="navigation"
        slot-scope="{ previous, next }"
        v-if="activeStep > 0"
      >
        <b-button
          outlined
          size="is-large"
          type="is-primary"
          icon-pack="fas"
          icon-left="backward"
          :disabled="previous.disabled"
          @click.prevent="previous.action"
        >
          Previous
        </b-button>
        <b-button
          outlined
          size="is-large"
          type="is-primary"
          icon-pack="fas"
          icon-right="forward"
          :disabled="next.disabled"
          @click.prevent="next.action"
        >
          Next
        </b-button>
      </template>

      <b-step-item label="Career" icon="fa fas fa-briefcase">
        <h1 class="caption">
          What are you focusing on?
        </h1>

        <b-field>
          <b-radio-button
            size="is-large"
            v-model="careerChoice"
            native-value="web engineering"
          >
            <span class="icon is-small">
              <i class="fas fa-laptop"></i>
            </span>
            <span>
              Javascript Engineering
            </span>
          </b-radio-button>
        </b-field>
        <b-field>
          <b-radio-button
            size="is-large"
            v-model="careerChoice"
            native-value="mobile engineering"
          >
            <span class="icon is-small">
              <i class="fas fa-mobile"></i>
            </span>

            <span>
              Mobile Engineering
            </span>
          </b-radio-button>
        </b-field>
        <b-field>
          <b-radio-button
            size="is-large"
            v-model="careerChoice"
            native-value="backend engineering"
          >
            <span class="icon is-small">
              <i class="fas fa-cloud"></i>
            </span>

            <span>
              Backend Engineering
            </span>
          </b-radio-button>
        </b-field>
        <b-field>
          <b-radio-button
            size="is-large"
            v-model="careerChoice"
            native-value="UI/UX design"
          >
            <span class="icon is-small">
              <i class="fas fa-brush"></i>
            </span>

            <span>
              UI/UX Design
            </span>
          </b-radio-button>
        </b-field>
        <b-field>
          <b-radio-button
            size="is-large"
            v-model="careerChoice"
            native-value="project management"
          >
            <span class="icon is-small">
              <i class="fas fa-briefcase"></i>
            </span>

            <span>
              Project Management
            </span>
          </b-radio-button>
        </b-field>
      </b-step-item>
      <b-step-item label="Experience" icon="fa fas fa-file-user">
        <h1 class="caption">
          Great choice. What's your experience with {{ careerChoice }}?
        </h1>

        <b-field>
          <b-radio-button
            size="is-large"
            v-model="levelChoice"
            :native-value="experienceDictionary.EXPLORING"
          >
            <span class="icon is-small">
              <i class="fas fa-user-astronaut"></i>
            </span>
            <span>
              Exploring
              <span> </span>
            </span>
            <span> </span>
          </b-radio-button>
        </b-field>

        <b-field>
          <b-radio-button
            size="is-large"
            v-model="levelChoice"
            :native-value="experienceDictionary.STUDENT"
          >
            <span class="icon is-small">
              <i class="fas fa-user-graduate"></i>
            </span>
            <span>
              Studying
              <span> </span>
            </span>
          </b-radio-button>
        </b-field>

        <b-field>
          <b-radio-button
            size="is-large"
            v-model="levelChoice"
            :native-value="experienceDictionary.JUNIORPRO"
          >
            <span class="icon is-small">
              <i class="fas fa-user-tie"></i>
            </span>
            <span>
              Working
              <span> </span>
            </span>
          </b-radio-button>
        </b-field>
      </b-step-item>
      <b-step-item label="Goals" icon="fa fas fa-bullseye">
        <h1 class="caption">
          Alright. What's your next goal in {{ careerChoice }} you'd like to
          accomplish?
        </h1>
        <b-field v-for="goal in goalOptions" :key="goal.title">
          <b-checkbox-button
            size="is-large"
            v-model="goalChoice"
            :native-value="goal.title"
          >
            <span class="icon is-small">
              <i :class="'fa ' + goal.icon"></i>
            </span>
            <span>
              {{ goal.title }}
              <span> {{ goal.caption }} </span>
            </span>
          </b-checkbox-button>
        </b-field>
      </b-step-item>
      <b-step-item
        class="signup container"
        label="Bridge"
        icon="fa fas fa-user-circle"
      >
        <h2 class="caption">
          Connect to the cloud and let's get started on your Career Bridge!
        </h2>
        <UserAuth authType="signup" @signupSuccess="completeSignup" />
      </b-step-item>
    </b-steps>
  </div>
</template>
<style scoped lang="scss">
@import "../_variables.scss";

.b-radio.radio.button,
.b-checkbox.checkbox.button {
  font-size: 2rem;
}

.onboarding-header nav span.step-title {
  color: $white;
}

.title {
  color: $white;
  font-size: 4.2rem;
}

.caption {
  color: $white;
  margin: 2rem 0;
  font-size: 34px;
}

.field {
  display: inline-flex;
  align-items: center;
  margin-right: 1rem;
}

.signup .field {
  display: block;
}

span span {
  display: block;
  font-size: 12px;
  font-family: "Open Sans";
  text-align: left;
}

p + .field .button {
  margin-right: 1rem;
}
</style>
<script>
import experienceToGoalsModel from "@/models/experienceToGoalsMap";
import UserAuth from "@/hoc/UserAuth.vue";

export default {
  name: "UserDashboard",
  components: {
    UserAuth,
  },
  data() {
    return {
      goalChoice: [],
      experienceDictionary: experienceToGoalsModel.experienceDictionary,
      signedIn: true,
      careerChoice: "",
      levelChoice: "",
      newGoal: "",
      newGoalDate: "",
      goalOptions: [],
      activeStep: 0,
    };
  },
  methods: {
    addGoal() {
      this.activeStep = 3;
    },

    completeSignup(response) {
      // if it's a success, then dispatch the data
      this.dispatch("createBridge", {
        username: response.username,
        bridge: {
          careerChoice: this.careerChoice,
          levelChoice: this.levelChoice,
          goals: this.goalOptions,
        },
      });
    },
  },
  watch: {
    careerChoice() {
      this.activeStep = 1;
    },
    levelChoice(newVal) {
      console.log(newVal);
      console.log(experienceToGoalsModel.experienceToGoalsMap(newVal));
      this.goalOptions = experienceToGoalsModel.experienceToGoalsMap(newVal);
      this.activeStep = 2;
    },
    activeStep() {
      const bridgeData = {
        careerChoice: this.careerChoice,
        levelChoice: this.levelChoice,
        goals: this.goalOptions,
      };
      localStorage.setItem("bridgeData", JSON.stringify(bridgeData));
    },
  },
};
</script>
