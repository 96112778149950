<template>
  <div id="app">
    <Navbar />
    <router-view />
  </div>
</template>

<style lang="scss">
@import "./_variables.scss";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Paytone+One&display=swap");

#app {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $white;
  background-color: $black;
}

// Global stepper related bug fix
a.step-link {
  span.step-title {
    color: $white;
  }
}

h1,
h2,
h3,
.button {
  font-family: "Paytone One", sans-serif;
}
.title {
  color: $white;
  font-size: 4.2rem;
}

nav.mainnav {
  background-color: $black;
  padding: 1rem;
  .navbar-item,
  .navbar-item.has-dropdown {
    color: $white;
    background-color: $black;

    &:hover {
      background-color: $black;
      color: $white;
      .navbar-link {
        background-color: $black;
        color: $white;
      }
    }

    .navbar-link {
      background-color: $black;
      color: $white;
    }
  }

  .navbar-dropdown {
    color: $black;
    border-color: $primary;
    border: 0;
    padding: 0;
  }
}
</style>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "App",
  components: {
    Navbar,
  },
};
</script>
