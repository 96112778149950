import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../pages/Home.vue";
import Discovery from "../pages/Discovery.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/discover",
    name: "Discovery",
    component: Discovery,
  },
  {
    path: "/signin",
    name: "Sign In",
    component: () =>
      import(/* webpackChunkName: "signin" */ "../pages/Auth/SignIn.vue"),
  },
  {
    path: "/create-pathway",
    name: "Create Pathway",
    component: () =>
      import(
        /* webpackChunkName: "createpathway" */ "../pages/Expert/PathwayCreate.vue"
      ),
  },
  {
    path: "/bridge-year",
    name: "Bridge Year",
    component: () =>
      import(/* webpackChunkName: "bridgeyear" */ "../pages/BridgeYear.vue"),
  },

  {
    path: "/verify",
    name: "Verify Account",
    component: () =>
      import(
        /* webpackChunkName: "signin" */ "../pages/Auth/VerifyAccount.vue"
      ),
  },
  {
    path: "/request-new-verification",
    name: "Request new Verification",
    component: () =>
      import(
        /* webpackChunkName: "signin" */ "../pages/Auth/RequestNewVerification.vue"
      ),
  },
  {
    path: "/pathway/:id",
    name: "Pathway",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "pathwayDiscovery" */ "../pages/PathwayConsume.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
