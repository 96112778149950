const experienceDictionary = {
  EXPLORING: "Exploring",
  STUDENT: "Studying",
  JUNIORPRO: "Working",
};

const goalDictionary = {
  ADVISORY: {
    title: "Find your Path",
    caption: "Get advice from a professionals in positions you want to be in",
    icon: "fas fa-road",
    id: "advisory",
  },
  COLLAB: (target) => {
    return {
      title: "Collab",
      caption: `Build your skill through practice projects with other ${target}`,
      icon: "fas fa-road",
      id: `${target}-collab`,
    };
  },
  PORTFOLIO: {
    title: "Portfolio",
    caption: "Build solo projects with the guidance of mentors",
    icon: "fas fa-road",
    id: "portfolio",
  },
  TRAIN_SIMULATOR: {
    title: "Train with a pro",
    caption: "Practice for the real world with a professional lead",
    icon: "fas fa-road",
    id: "simulator",
  },
  TRAIN_WORKSHOP: {
    title: "Learn industry level techniques with a pro",
    icon: "fas fas-road",
    id: "workshop",
  },
  EXPERIENCE: {
    title: "Experience",
    icon: "fas fa-road",
    id: "experience",
  },
  TRAIN_BRIDGE_YEAR: {
    title: "Bridge Year",
    caption: "Go from 0 to competent in a year",
    icon: "fas fa-road",
    id: "bridge-year",
  },
};

const experienceToGoalsMap = (experience) => {
  goalDictionary;

  switch (experience) {
    case experienceDictionary["EXPLORING"]:
      return [
        goalDictionary.ADVISORY,
        goalDictionary.COLLAB("explorers"),
        goalDictionary.PORTFOLIO,
        goalDictionary.TRAIN_BRIDGE_YEAR,
      ];
    case experienceDictionary["STUDENT"]:
      return [
        goalDictionary.COLLAB("students"),
        goalDictionary.TRAIN_SIMULATOR,
        goalDictionary.TRAIN_BRIDGE_YEAR,
        goalDictionary.EXPERIENCE,
        goalDictionary.PORTFOLIO,
      ];
    case experienceDictionary["JUNIORPRO"]:
      return [
        goalDictionary.COLLAB("young professionals"),
        goalDictionary.TRAIN_SIMULATOR,
        goalDictionary.TRAIN_WORKSHOP,
        goalDictionary.PORTFOLIO,
      ];
  }
};

export default { experienceDictionary, goalDictionary, experienceToGoalsMap };
