<template>
  <b-navbar class="mainnav">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
          class="image"
          src="../assets/logo.png"
          alt="Lightweight UI components for Vue.js based on Bulma"
        />
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item href="/discover">
        Discover
      </b-navbar-item>
      <b-navbar-dropdown label="My Pathways">
        <b-navbar-item href="/pathway/dank-coder-javascript">
          Dank Coder Javascript Path
        </b-navbar-item>
        <b-navbar-item href="/pathway/rethink-the-world">
          Rethink the World Python Path
        </b-navbar-item>
      </b-navbar-dropdown>
      <b-navbar-dropdown label="My Experiences">
        <b-navbar-item href="/bridge-year">
          Bridge Year
        </b-navbar-item>
        <b-navbar-item href="/corporate-workshops">
          Corporate Workshops
        </b-navbar-item>
        <b-navbar-item href="/industry-simulator">
          Industry Simulators
        </b-navbar-item>
      </b-navbar-dropdown>
    </template>
    <template slot="end">
      <b-navbar-item v-if="isLoggedIn()" @click="logout">
        LogOut
      </b-navbar-item>
      <b-navbar-item href="/signin" v-else>
        Login
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import authHelper from "@/mixins/authHelper";

export default {
  name: "Navbar",
  mixins: [authHelper],
  methods: {
    async logout() {
      this.$store.dispatch("signOut");
    },
  },
};
</script>
