<template>
  <div class="pathway-display">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h1 class="search-title">Discover</h1>
        </div>
        <div class="column search-filter-box">
          <b-field>
            <b-input placeholder="Search" icon-pack="fas" icon="search">
            </b-input>
          </b-field>
          <b-field>
            <button class="button is-dark" @click="toggleFilter">
              <span class="icon is-small">
                <i class="fas fa-filter"></i>
              </span>
              <span> {{ this.showFilters ? "Hide" : "Show" }} Filters </span>
            </button>
            <div v-if="this.showFilters" class="filter-box">
              <b-checkbox> Only Pro Pathways </b-checkbox>
            </div>
          </b-field>
        </div>
      </div>

      <DiscoverAssets
        :results="results"
        @openPathwayModal="openPathwayModal"
        @consumePathway="consumePathway"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-title {
  font-size: 2.15rem;
  font-weight: 800;
  text-align: left;
}
.pathway-display {
  min-height: 100vh;
}

.search-filter-box {
  display: inline-flex;

  div {
    margin-right: 1rem;

    button {
      padding: 0 2rem;
    }
  }
}
</style>

<script>
import DiscoverAssets from "@/hoc/DiscoverAssets";
import pathwaysJSON from "@/models/mocks/pathways.json";

export default {
  name: "Discovery",
  components: {
    DiscoverAssets,
  },
  data() {
    return {
      activeResult: {},
      showFilters: false,
      showPathwayModal: false,
      results: pathwaysJSON,
    };
  },
  methods: {
    toggleFilter() {
      this.showFilters = !this.showFilters;
    },
  },
};
</script>
