<template>
  <div class="container question-pad">
    <div v-if="activeQuestions.length > 0">
      <div>Sup</div>
    </div>
    <ul class="questions">
      <li v-for="question in questionPad" :key="question.id">
        <div class="columns is-mobile">
          <div class="column is-1">
            <b-checkbox @click="toggleQuestion(question)"></b-checkbox>
          </div>
          <div class="column is-8 question">
            <div>{{ question.question }}</div>
            <div class="reference">
              Asked from {{ question.references.title }}
            </div>
          </div>
          <div class="column">
            <button class="button is-primary is-outlined">
              <span v-if="question.isCompleted">
                <span> View Answers </span>
                <span class="icon is-small">
                  <i class="fas fa-question"></i>
                </span>
              </span>
              <span v-else>
                <span> Get Answers </span>
              </span>
            </button>
            <button
              class="button is-primary is-outlined"
              @click="removeQuestion(question)"
            >
              <span class="icon is-small is-warning">
                <i class="fas fa-trash "></i>
              </span>
            </button>
          </div>
        </div>
      </li>
    </ul>
    <b-field>
      <b-input
        size="is-small"
        maxlength="250"
        type="textarea"
        v-model="newQuestion"
      ></b-input>
    </b-field>
    <button
      class="button is-primary is-outlined"
      :disabled="newQuestion.length === 0"
    >
      <span @click="addQuestion()"> Add Question </span>
      <span class="icon is-small">
        <i class="fas fa-user"></i>
      </span>
    </button>
  </div>
</template>
<style scoped lang="scss">
.question-pad {
  text-align: left;

  textarea {
    margin-top: 2rem;
  }

  button {
    margin-right: 1rem;
  }

  li {
    padding: 1rem 0;
    vertical-align: middle;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }

  .question {
    .reference {
      font-size: 12px;
    }
  }
}
</style>
<script>
export default {
  name: "QuestionPad",
  data() {
    return {
      activeQuestions: [],
      newQuestion: "",
      questionPad: this.currentQuestions || [],
    };
  },
  props: ["currentQuestions", "activeResource"],
  methods: {
    toggleQuestion(question) {
      if (
        this.activeQuestions.includes(
          (activeQuestion) => activeQuestion.id === question.id
        )
      ) {
        return this.activeQuestions.filter(
          (activeQuestion) => activeQuestion.id === question.id
        );
      } else {
        return false;
      }
    },
    addQuestion() {
      const newQuestions = this.questionPad;

      newQuestions.push({
        question: this.newQuestion,
        answers: [],
        references: {
          id: this.activeResource.id,
          title: this.activeResource.title,
          source: this.activeResource.source,
        },
      });

      this.questionPad = newQuestions;

      this.newQuestion = "";
    },
  },
};
</script>
