<template>
  <div
    class="card"
    :style="{
      background: `linear-gradient(
                  rgba(0, 0, 0, 0.45), 
                  rgba(0, 0, 0, 0.45)
                ),url(${result.imageURL})`,
    }"
    @mouseover="mouseEnter"
    @mouseleave="mouseExit"
    @click="openCardModal(result)"
  >
    <div class="card-content">
      <div class="title">
        {{ result.title }}
      </div>
      <div class="columns">
        <div class="column">8 resources</div>
        <div class="column">100 favorited</div>
      </div>
      <div v-if="!fixed">
        <div class="columns" v-if="isHovering">
          <div class="column">
            <span> More Info </span>
            <span class="icon is-small">
              <i class="fas fa-info-circle"></i>
            </span>
          </div>
          <div class="column" @click="consumeCardAsset(result)">
            <span> View Pathway </span>
            <span class="icon is-small">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </span>
          </div>
        </div>
      </div>
      <div v-else class="else">
        <div class="columns">
          <div class="column">
            <span> More Info </span>
            <span class="icon is-small">
              <i class="fas fa-info-circle"></i>
            </span>
          </div>
          <div class="column" @click="consumeCardAsset(result)">
            <span> View Pathway </span>
            <span class="icon is-small">
              <i class="fas fa-arrow-alt-circle-right"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.card {
  &:hover {
    cursor: pointer;
  }
}
.card-content {
  color: white;

  .title {
    color: white;
    font-size: 20px;
    font-weight: 800;
  }

  div:nth-child(2) {
    div:nth-child(2) {
      text-align: right;
    }
  }

  margin-top: 5rem;
  text-align: left;
}
</style>
<script>
export default {
  name: "DiscoverCard",
  props: [
    "result",
    "openModal",
    "consumeAsset",
    "fixed",
    "display",
    "discover",
  ],
  data() {
    return {
      isHovering: false,
    };
  },
  methods: {
    openCardModal(result) {
      this.$emit("openModal", result);
    },
    consumeCardAsset(result) {
      this.$emit("consumeAsset", result);
    },
    mouseEnter() {
      this.isHovering = true;
    },
    mouseExit() {
      this.isHovering = false;
    },
  },
};
</script>
