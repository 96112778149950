<template>
  <div>
    <b-modal v-model="showPathwayModal">
      <div class="pathway-detail-modal card">
        <div
          class="modal-title"
          :style="{
            background: `linear-gradient(
                  rgba(0, 0, 0, 0), 
                  rgba(0, 0, 0, 0.9)
                ),url(${this.activeResult.imageURL})`,
          }"
        >
          <h2>{{ this.activeResult.title }}</h2>
        </div>
        <div class="container">
          <div class="action-buttons">
            <button
              class="button is-primary"
              @click="consumeAsset(activeResult)"
            >
              <span> Play </span>
              <span class="icon is-small">
                <i class="fas fa-play"></i>
              </span>
            </button>
            <button class="button is-primary">
              <span class="icon is-small">
                <i class="fas fa-plus"></i>
              </span>
            </button>
            <button class="button is-primary">
              <span class="icon is-small">
                <i class="fas fa-thumbs-up"></i>
              </span>
            </button>
          </div>

          {{ this.activeResult.info }}
        </div>
      </div>
    </b-modal>

    <div class="columns is-multiline" v-if="false">
      <div
        class="column is-one-quarter"
        v-for="result in results"
        :key="result.id"
      >
        <DiscoverCard
          :result="result"
          @openModal="openModal"
          @consumeAsset="consumeAsset"
          :fixed="fixed"
          display="carousel"
          discover="experiences"
        />
      </div>
    </div>
    <div v-else class="columns is-mobile">
      <div class="column is-12">
        <b-carousel-list
          v-model="test"
          :data="results"
          :items-to-show="3"
          :arrow="true"
          :arrow-hover="false"
          icon-size="is-large"
          icon-pack="fa"
          animated="fade"
        >
          <template slot="item" slot-scope="result">
            <DiscoverCard
              :result="result"
              @openModal="openModal"
              @consumeAsset="consumeAsset"
              :fixed="fixed"
            />
          </template>
        </b-carousel-list>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import "../_variables.scss";

.pathway-detail-modal {
  background: $black;
  color: $white;
  text-align: left;

  .modal-title {
    padding: 10rem 2rem 2rem 2rem;
    color: $white;
  }

  h2 {
    font-weight: 400;
    font-size: 24px;
    text-align: left;
  }

  .action-buttons {
    margin: 1rem 2rem 0;

    button {
      margin-right: 1rem;
    }
  }
}
</style>
<script>
import DiscoverCard from "@/components/DiscoverCard.vue";

export default {
  name: "DiscoverAssets",
  components: {
    DiscoverCard,
  },
  data() {
    return {
      test: 0,
      activeResult: {},
      showPathwayModal: false,
    };
  },
  props: ["results", "openPathwayModal", "consumePathway", "fixed"],
  methods: {
    openModal(result) {
      this.activeResult = result;
      this.showPathwayModal = true;

      this.$emit("openPathwayModal", result);
    },
    consumeAsset(result) {
      this.$router.push(`pathway/${result.uri}`);

      this.$emit("consumePathway", result);
    },
  },
};
</script>
