<template>
  <div class="home">
    <div v-if="isLoggedIn()">
      <UserDashboard />
    </div>

    <div v-else>
      <UserOnboarding />
    </div>
  </div>
</template>

<style lang="scss">
.home {
  min-height: 100vh;
}
</style>

<script>
// @ is an alias to /src
import UserDashboard from "@/hoc/UserDashboard.vue";
import UserOnboarding from "@/hoc/UserOnboarding.vue";
import authHelper from "@/mixins/authHelper";

export default {
  name: "Home",
  components: {
    UserDashboard,
    UserOnboarding,
  },
  mixins: [authHelper],
  data() {
    return {
      signedIn: this.isLoggedIn(),
    };
  },
};
</script>
