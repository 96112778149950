<template>
  <div>
    <div class="jumbo">
      <div class=" container">
        <div class="columns">
          <div class="column">
            <h2 class="is-title is-4">Welcome back to {{ userCareer }}</h2>
            <div class="action-box">
              <h4 v-if="overview.user">
                Currently on an {{ overview.user.experience_choice }} Bridge
              </h4>
            </div>
          </div>
          <div class="column">
            <div class="goals">
              <h3>Bridge Goals</h3>
              <div v-for="goal in userGoals" :key="goal.title">
                {{ goal.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-loading
        :is-full-page="true"
        v-model="loading"
        :can-cancel="false"
      ></b-loading>
    </div>

    <div class="container">
      <div class="columns">
        <div class="column is-12" v-if="!showExperiences">
          <h2>Experiences For You</h2>
          <div>
            <DiscoverAssets
              :results="dashboardData.suggestions.experiences"
              :fixed="true"
            />
          </div>
        </div>
        <div class="column is-12" v-if="showExperiences">
          <h2>Experience</h2>
          <p>Discover experiences</p>
        </div>
        <div class="column" v-if="showExperiences">
          <h2>Discover Mentors</h2>
        </div>
      </div>
      <div class="columns">
        <div
          class="column is-12"
          v-if="dashboardData.bridge.pathways.length === 0"
        >
          <h2>Discover Pathways</h2>
          <DiscoverAssets
            :results="dashboardData.suggestions.pathways"
            :fixed="true"
          />
        </div>
        <div class="column" v-if="dashboardData.bridge.pathways.length > 0">
          <h2>Pathway</h2>
          <p>Continue Pathway</p>
        </div>

        <div class="column" v-if="dashboardData.bridge.pathways.length > 0">
          <h2>Question Pad</h2>
          <QuestionPad />
        </div>
      </div>
      <div class="columns" v-if="false">
        <div class="column">
          <h2>Squad</h2>
        </div>
        <div class="column">
          <h2>Discover Collabs</h2>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import "../_variables.scss";

.jumbo {
  background-color: $dark-purp; //#8444ad90;
  min-height: 25vh;
  align-items: middle;
  padding: 4rem 0;
  border-radius: 0 0 1rem 1rem;

  position: relative;
  margin-bottom: 20px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: repeat-x;
  }

  &::before {
    height: 10px;
    background-size: 20px 20px;
    background-image: radial-gradient(
      circle at 10px -5px,
      transparent 12px,
      $black 13px
    );
  }

  &::after {
    height: 15px;
    background-size: 40px 20px;
    background-image: radial-gradient(
      circle at 10px 15px,
      $black 12px,
      transparent 13px
    );
  }

  text-align: left;

  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.2rem;
  }
}

h2 {
  text-align: left;
  font-size: 2rem;
}

.block {
  border: 1px $black solid;
  background: $white;
  margin: 3rem;
  border-radius: 1rem;
  color: $black;
  text-align: left;
  min-height: 10rem;
  align-content: center;
}
</style>
<script>
import DiscoverAssets from "@/hoc/DiscoverAssets";
import QuestionPad from "@/hoc/QuestionPad.vue";
import pathwaysJSON from "@/models/mocks/pathways.json";
import authHelper from "@/mixins/authHelper";

export default {
  name: "UserDashboard",
  components: {
    QuestionPad,
    DiscoverAssets,
  },
  mixins: [authHelper],
  async created() {
    this.loading = true;
    const token = await this.getAuthIDToken();
    const data = await fetch(`${this.globalVar.uri}my-dash-data`, {
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    });

    const dataSON = await data.json();

    this.overview = dataSON;

    this.userGoals = JSON.parse(dataSON.user.goals);
    this.loading = false;
  },
  computed: {
    userCareer() {
      if (this.overview)
        if (this.overview.user) return this.overview.user.career_choice;

      return "...";
    },
    showExperiences() {
      if (this.dashboardData) {
        if (this.dashboardData.bridge) {
          if (this.dashboardData.bridge.experiences) {
            return this.dashboardData.bridge.experiences.length > 0;
          }
        }
      }
      return false;
    },
  },
  data() {
    return {
      loading: false,
      goalChoice: "",
      overview: {},
      userGoals: {},
      dashboardData: {
        user: {
          badges: [],
          status: {
            message: "",
            systemMessage: "",
          },
          goals: [],
        },
        bridge: {
          experiences: [], //ids
          pathways: [],
          mentors: [],
          collabs: {
            squad: [],
            plays: [],
          },
        },
        questionPad: {
          pad_id: "userID+bridgeID",
          user_id: "",
          bridge_id: "",
          questions: ["id", "id"],
        },
        suggestions: {
          experiences: pathwaysJSON,
          pathways: pathwaysJSON,
          mentors: [],
          plays: [],
        },
      },
    };
  },
};
</script>
